<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    :width="size"
    :height="size"
    viewBox="0 0 24 24"
    fill="currentColor"
  >
    <g clip-path="url(#clip0_13190_36880)">
      <rect
        width="20"
        height="20"
        transform="translate(0 0.175781)"
        fill="white"
      />
      <path
        d="M18.6582 10.1696L-8.67773 10.1694"
        stroke="#AFAFAF"
        stroke-width="4"
        stroke-dasharray="4 4"
      />
    </g>
    <defs>
      <clipPath id="clip0_13190_36880">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0 0.175781)"
        />
      </clipPath>
    </defs>
  </svg>
</template>

<script>
export default {
  name: 'IcTargetTrend',
  props: {
    size: {
      type: [String, Number],
      default: 24,
    },
  },
}
</script>

<style scoped></style>
